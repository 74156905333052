<template>
    <b-row>
        <b-col cols="6">
            <b-form-group>
                <label>Cód usuário</label>
                <b-form-input 
                    id="cd_usuario"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_usuario"
                />
            </b-form-group>
        </b-col>
        <b-col cols="6">
            <b-form-group>
                <label>Cód fração</label>
                <b-form-input 
                    id="cd_fracao"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_fracao"
                />
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-form-group>
                <label>Descrição</label>
                <b-form-input 
                    id="descricao"
                    autocomplete="off"
                    v-model="form.descricao"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Vigência</label>
                <b-form-input 
                    id="vigencia"
                    autocomplete="off"
                    type="date"
                    v-model="form.vigencia"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Identificação</label>
                <b-form-input 
                    id="identif"
                    autocomplete="off"
                    maxlength="1"
                    v-model="form.identif"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço emb. fábrica</label>
                <money 
                    id="pc_emb_fab"
                    v-model="form.pc_emb_fab"
                    class="form-control"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço emb. venda</label>
                <money 
                    id="pc_em_ven"
                    v-model="form.pc_em_ven"
                    class="form-control"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço emb. usuário</label>
                <money 
                    id="pc_em_usu"
                    v-model="form.pc_em_usu"
                    class="form-control"
                />
            </b-form-group>
        </b-col>

        <b-col cols="3">
            <b-form-group>
                <label>Preço fração fábrica</label>
                <b-form-input 
                    id="pc_fr_fab"
                    autocomplete="off"
                    type="number"
                    v-model="form.pc_fr_fab"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço fração venda</label>
                <b-form-input 
                    id="pc_fr_ven"
                    autocomplete="off"
                    type="number"
                    v-model="form.pc_fr_ven"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço fração usuário</label>
                <b-form-input 
                    id="pc_fr_usu"
                    autocomplete="off"
                    type="number"
                    v-model="form.pc_fr_usu"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Tipo embal.</label>
                <b-form-input 
                    id="tp_embal"
                    autocomplete="off"
                    maxlength="10"
                    v-model="form.tp_embal"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Qtde embal.</label>
                <b-form-input 
                    id="qtde_embal"
                    autocomplete="off"
                    type="number"
                    v-model="form.qtde_embal"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Qtde fração</label>
                <b-form-input 
                    id="qtde_frac"
                    autocomplete="off"
                    type="number"
                    v-model="form.qtde_frac"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Perc. lucro</label>
                <b-form-input 
                    id="perc_lucr"
                    autocomplete="off"
                    type="number"
                    v-model="form.perc_lucr"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Tipo alt.</label>
                <b-form-input 
                    id="tip_alt"
                    autocomplete="off"
                    maxlength="1"
                    v-model="form.tip_alt"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Fábrica</label>
                <b-form-input 
                    id="frabrica"
                    autocomplete="off"
                    v-model="form.frabrica"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. simpro</label>
                <b-form-input 
                    id="cd_simpro"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_simpro"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. mercado</label>
                <b-form-input 
                    id="cd_mercado"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_mercado"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Perc. desc</label>
                <b-form-input 
                    id="perc_desc"
                    autocomplete="off"
                    type="number"
                    v-model="form.perc_desc"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>IPI produto</label>
                <b-form-input 
                    id="ipi_produto"
                    autocomplete="off"
                    type="number"
                    v-model="form.ipi_produto"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Registro Anvisa</label>
                <b-form-input 
                    id="registro_anvisa"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.registro_anvisa"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Validade Anvisa</label>
                <b-form-input 
                    id="validade_anvisa"
                    autocomplete="off"
                    type="date"
                    v-model="form.validade_anvisa"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. de barra</label>
                <b-form-input 
                    id="cd_barra"
                    autocomplete="off"
                    maxlength="100"
                    v-model="form.cd_barra"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Lista</label>
                <b-form-input 
                    id="lista"
                    autocomplete="off"
                    maxlength="1"
                    v-model="form.lista"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Hospitalar</label>
                <multiselect
                    id="hospitalar"
                    v-model="form.hospitalar"
                    :options="['S', 'N']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Fracionar</label>
                <multiselect
                    id="fracionar"
                    v-model="form.fracionar"
                    :options="['S', 'N']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. TUSS</label>
                <b-form-input 
                    id="cd_tuss"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_tuss"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. classificação</label>
                <b-form-input 
                    id="cd_classif"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_classif"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Cód. ref. pro.</label>
                <b-form-input 
                    id="cd_ref_pro"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cd_ref_pro"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Genérico</label>
                <multiselect
                    id="generico"
                    v-model="form.generico"
                    :options="['S', 'N']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Diversos</label>
                <multiselect
                    id="diversos"
                    v-model="form.diversos"
                    :options="['S', 'N']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
    </b-row>
</template>
<script>
export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {},
    data() {
        return {
            form: {}
        }
    },
    methods: {},
    watch: {
        form: {
            deep: true,
            immediate: true,
            handler(form) {
                this.$emit('input', form)
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>